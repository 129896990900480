<template>
  <div class="elec-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="elec-title"
          v-lazy="require('../../../assets/images/title9.png')"
        />
        <div class="elec-dd">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="pp-dd">
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
                <span
                  class="s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "electronicData-spf",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/qt-5.png"),
          name: `远离消费端市场<br>声音难以被听到`,
        },
        {
          icon: require("../../../assets/images/qt-6.png"),
          name: `终端渠道多、中间层级多<br>难以把控`,
        },
        {
          icon: require("../../../assets/images/qt-7.png"),
          name: `产品流向不清晰，数据沉淀少<br>生产计划难定`,
        },
        {
          icon: require("../../../assets/images/qt-8.png"),
          name: `库存管理压力大，渠道管控被动`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.elec-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.elec-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.elec-dd {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -0.4rem;
    li {
      float: left;
      margin-left: 0.4rem;
      margin-top: 1.4rem;
      .pp-dd {
        width: 5.7rem;
      }
      .m1 {
        width: 100%;
      }
      .s1 {
        font-size: 0.36rem;
        display: block;
        color: #6aa5ff;
        line-height: 1.5;
      }
    }
  }
}
</style>